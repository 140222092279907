import React from 'react'
import Navbar from "../components/Navbar";
import PrivacyImg from '../assets/privacy.png'
import UPDATE from '../assets/update.png'
import Footer from '../components/Footer';

export const Privacy = () => {
  return (
    <>
      <Navbar />
      <div className='flex flex-col items-center gap-y-20 select-none'>
        <hr className='bg-gray-600 w-5/6' />
        
        <div className='text-justify flex flex-col self-start xl:px-40 lg:px-20 md:px-16 px-10'>
          <span className='flex gap-2 items-center mb-10'>
            <img className="w-8" src={PrivacyImg} alt='' />
            <div className='text-2xl font-semibold'>سیاست‌های حریم خصوصی</div>
          </span>
          <span className='flex gap-2 items-center mb-10 mr-2'>
              <img className="w-5" src={UPDATE} alt='' />
              <div className=''>آخرین به‌روزرسانی: ۱۴۰۳/۰۸/۲۲</div>
          </span>
          <span className='leading-8 mr-2 mb-8'>
          <strong>برنامه لیستان | دفترچه همراه</strong> (که در قالب یک برنامه وب پیش‌رونده یا PWA ارائه می‌شود) به حریم خصوصی کاربران خود احترام می‌گذارد و برای حفاظت از اطلاعات شخصی شما تلاش می‌کند. این سیاست حفظ حریم خصوصی توضیح می‌دهد که چگونه اطلاعات شما جمع‌آوری، استفاده و محافظت می‌شود.
          </span>
          <span className='mr-2 leading-9'>
            <div className='font-semibold'>اطلاعاتی که جمع‌آوری می‌کنیم</div>
            <li className='font-semibold'>ایجاد حساب کاربری:
              <span className='font-normal'> هنگام ثبت‌نام، نام کاربری و کلمه عبور شما را جمع‌آوری می‌کنیم.</span>
            </li>
            <li className='font-semibold'>ایمیل (اختیاری):
              <span className='font-normal'> پس از ثبت‌نام، می‌توانید ایمیل خود را برای بازنشانی کلمه عبور ارائه دهید.</span>
            </li>

          </span>
          <br />
          <span className='mr-2 leading-9'>
            <div className='font-semibold'>نحوه استفاده از اطلاعات</div>
            <li className='font-semibold'>ارائه خدمات:
              <span className='font-normal'> اطلاعات حساب کاربری شما برای فراهم کردن دسترسی ایمن و شخصی به خدمات ما به کار می‌رود.</span>
            </li>
            <li className='font-semibold'>بازنشانی کلمه عبور:
              <span className='font-normal'> ایمیل شما برای ارسال لینک‌های بازنشانی کلمه عبور استفاده می‌شود.</span>
            </li>
          </span>
          <br />
          <span className='mr-2 leading-9'>
            <div className='font-semibold'>حفاظت از اطلاعات</div>
            <li>اطلاعات کاربران با استانداردهای امنیتی به سرور ما منتقل می‌شود.</li>
            <li>کلمه عبور شما به‌صورت رمزگذاری شده (hashed) ذخیره می‌شود و به‌صورت متنی ذخیره یا مشاهده نخواهد شد.</li>
          </span>
          <br />
          <span className='mr-2 leading-9'>
            <div className='font-semibold'>اشتراک‌گذاری اطلاعات با شخص ثالث</div>
            <li>ما اطلاعات شخصی کاربران را به شخص ثالث منتقل نخواهیم کرد، مگر در مواردی که به حکم قانون یا دستورات قضایی الزام‌آور باشد.</li>
          </span>
          <br />
          <span className='mr-2 leading-9'>
            <div className='font-semibold'>حقوق کاربر</div>
            <li className='font-semibold'>دسترسی و اصلاح:
              <span className='font-normal'> کاربران می‌توانند در هر زمان به اطلاعات حساب خود دسترسی پیدا کرده و کلمه عبور یا نشانی ایمیل را اصلاح کنند.</span>
            </li>
            <li className='font-semibold'>حذف حساب:
              <span className='font-normal'> در صورت تمایل، کاربران می‌توانند درخواست حذف حساب کاربری خود را با بخش پشتیبانی در میان بگذارند.</span>
            </li>
          </span>
          <br />
          <span className='mr-2 leading-9'>
            <div className='font-semibold'>تغییرات در این سیاست حفظ حریم خصوصی</div>
            <p> ممکن است این بیانیه حفظ حریم خصوصی به‌روزرسانی شود. در صورت هرگونه به‌روزرسانی، تغییرات در این صفحه منعکس خواهند شد و تاریخ به‌روزرسانی نیز اصلاح می‌شود.</p>
          </span>
          <br />
        </div>
      </div>
      <Footer />
    </>
  )
}
