import React from "react";
import IDEA from '../assets/idea.png'
import SIMPLE from '../assets/simple.png'
import EDIT from '../assets/edit.png'
import CALENDAR from '../assets/calendar.png'
import MULTIPLE from '../assets/multiple.png'
import CLOUD from '../assets/cloud.png'

const cardData = [
  {
    icon: IDEA,
    bgColor: "bg-sky-200",
    title: "ثبت سریع ایده‌ها و کارها",
    description:
      "لیستان به کاربران اجازه می‌دهد تا با یک ضربه ساده، ایده‌ها، کارها و یادداشت‌های خود را بدون نیاز به صرف زمان زیاد وارد کنند. این ویژگی به‌خصوص برای دانشجویانی که همیشه در حال حرکت هستند، بسیار مفید است.",
  },
  {
    icon: SIMPLE,
    bgColor: "bg-orange-200",
    title: "طراحی ساده و کاربرپسند",
    description:
      "رابط کاربری لیستان به گونه‌ای طراحی شده است که حتی در شرایط پرمشغله، کاربران می‌توانند به‌راحتی و با کمترین تلاش، کارها و یادداشت‌های خود را مدیریت کنند.",
  },
  {
    icon: EDIT,
    bgColor: "bg-green-200",
    title: "قابلیت ویرایش و شخصی‌سازی",
    description:
      "کاربران می‌توانند پس از ثبت اولیه، جزئیات بیشتری به کارها و یادداشت‌های خود اضافه کنند و بر اساس اولویت‌های شخصی، آن‌ها را سازماندهی نمایند.",
  },
  {
    icon: CALENDAR,
    bgColor: "bg-yellow-200",
    title: "استفاده از تقویم جلالی",
    description:
      "این ویژگی به کاربران ایرانی کمک می‌کند تا با استفاده از تاریخ‌های شمسی، کارها و یادداشت‌ها خود را به طور موثر برنامه‌ریزی و مدیریت کنند.",
  },
  {
    icon: MULTIPLE,
    bgColor: "bg-purple-200",
    title: "پشتیبانی از دستگاه‌های مختلف",
    description:
      "لیستان امکان دسترسی به کارها و یادداشت‌ها را از دستگاه‌های مختلف مانند موبایل و رایانه فراهم می‌کند و از سیستم‌عامل‌های گوناگونی پشتیبانی می‌کند.",
  },
  {
    icon: CLOUD,
    bgColor: "bg-rose-200",
    title: "ذخیره‌سازی ابری",
    description:
      "با استفاده از فضای ابری، کاربران می‌توانند از هر مکانی و با هر دستگاهی به کارها و یادداشت‌های خود دسترسی داشته باشند و آن‌ها را به‌روز کنند.",
  },
];


const Feature = () => {
  return (
    <section className="flex flex-col mt-24 items-center gap-6" aria-labelledby="features-title">
      <div className="text-md text-cyan-600">از ما بیشتر بدان</div>
      <h2 id="features-title" className="text-3xl font-semibold text-zinc-600">
        ویژگی‌های لیستان
      </h2>
      <p className="p-5 pt-0 text-wrap text-zinc-500 text-center leading-8 text-base lg:w-7/12">
        لیستان از یک نیاز واقعی در دوره دانشجویی شکل گرفته است. در مواقعی مانند پیاده‌روی به سمت دانشگاه،
        نیاز به ثبت سریع ایده‌ها، کارها و یادداشت‌هایی که به ذهن می‌رسند، بدون نیاز به صرف زمان زیاد برای استفاده از تلفن همراه وجود داشت.
        به همین دلیل، طراحی این برنامه به گونه‌ای است که افزودن کارها و یادداشت‌ها تنها با ضربه‌ای به یک کلید انجام می‌شود.
      </p>

      <div className="flex flex-wrap gap-8 justify-center mx-2">
        {cardData.map((card, index) => (
          <div
            key={index}
            className='p-6 rounded-lg shadow-md w-60 cursor-pointer transform transition-transform duration-200 hover:-translate-y-2 hover:shadow-lg'
            role="article"
          >
            <div className={`flex justify-center items-center mb-4 w-14 h-14 rounded-full ${card.bgColor}`} aria-hidden="true">
              <img className="w-[3rem] h-[3rem]" src={card.icon} alt="" />
            </div>
            <h3 className="text-lg text-zinc-500 font-semibold mb-4">{card.title}</h3>
            <p className="text-md text-zinc-400 text-justify">{card.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Feature;
